@font-face {
  font-family: 'PrudentialModern';
  src: url('https://branding.123seguro.com/fonts/Prudential%20Modern/PrudentialModern-BoldSemCon.ttf');
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://branding.123seguro.com/fonts/Gilroy/Gilroy%20Semibold/Gilroy-Semibold.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://branding.123seguro.com/fonts/Gilroy/Gilroy%20Semibold/Gilroy-Semibold.otf');
  font-weight: 600;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://branding.123seguro.com/fonts/Gilroy/Gilroy%20Light/Gilroy-Light.otf');
  font-weight: 300;
}
